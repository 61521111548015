import api from '@/apis/api'
/**
 * 生产数据相关api
 */
export class dataAnalyseApi extends api {

    getList (condition) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/getProductDataList`,
            method: 'post',
            data: condition
        })
    }
        
    getManufacturers(){
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/getManufacturers`,
            method: 'get'
        })
    }
}

export default new dataAnalyseApi('dataAnalysis')
