<template>
  <list-condition-template ref="table" :rows="rows" :total="total"
   :multiple-selected="true" :footer-show="false" @selected="selectedProductions">
    <template #condition>
      <el-form-item label="分析时间段" required>
        <el-date-picker
            v-model="dateRange"
            type="monthrange"
            :disabled-date="disabledDate"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="YYYY-MM-DD HH:mm:ss"
            @change="changeTime"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="差额百分比(%)">
        <el-input type="text" v-model="listQuery.percentDifference" placeholder="设置差额百分比(%)"/>
      </el-form-item>
      <el-form-item>
        <el-select v-model="listQuery.manufacturerId" placeholder="ODM 制造商搜索" clearable filterable>
          <el-option v-for="item in manufacturerList" :key="item.id" :label="item.name" :value="item.id"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleQuery">搜索</el-button>
        <el-button type="primary" icon="el-icon-download" @click="exportAnalyseData">导出分析数据</el-button>
      </el-form-item>
    </template>
    <template #columns>
      <el-table-column label="ODM 制造商" align="center" sortable prop="manufacturerName"/>
      <el-table-column label="标签申请数" align="center" sortable prop="labelNum"/>
      <el-table-column label="商品制造数" align="center" sortable prop="productNum"/>
      <el-table-column label="生产差额" align="center" sortable prop="difference"/>
      <el-table-column label="差额百分比" align="center" sortable>
        <template #default="scope">
          {{ (scope.row.percentDifference * 100).toFixed(0) + '%' }}
        </template>
      </el-table-column>
    </template>
  </list-condition-template>
</template>

<script>
import ListConditionTemplate from "@/components/templates/listConditionTemplate";
import {ElMessage} from "element-plus";
import dataAnalyseApi from "@/apis/data/production";
import XLSX from 'xlsx';

export default {
  components: {ListConditionTemplate},
  data() {
    return {
      total: 0,
      rows: [],
      listQuery: {
        percentDifference: '',
        manufacturerId: '',
        startTime: '',
        endTime: ''
      },
      dateRange: '',
      manufacturerList: [],
      selectedProductionList: [],
    }
  },
  created() {
    this.initDateRange()
    this.getAllManufacture();
    // this.getList()
  },
  methods: {
    initDateRange() {
      const date = new Date();
      const monthStart = new Date(date.getFullYear(), date.getMonth()-1, 1); // 获取本月第一天的日期时间
      const monthEnd = new Date(date.getFullYear(), date.getMonth(), 0, 23, 59, 59); // 获取本月最后一天的日期时间（时间为23:59:59）
      const beg = this.dateFormatter(monthStart)
      const end = this.dateFormatter(monthEnd)
      this.dateRange = [beg, end] //将值设置给插件绑定的数据
      this.listQuery.startTime = beg
      this.listQuery.endTime = end
    },
    disabledDate(time) {
      const nowDate = new Date();
      nowDate.setMonth(nowDate.getMonth() - 1)
      return time.getTime() > nowDate.getTime();
    },
    changeTime(data) {
      if (data != null && data.length === 2) {
        const sDate = new Date(data[0])
        const eDate = new Date(data[1])
        const monthStart = new Date(sDate.getFullYear(), sDate.getMonth(), 1); // 获取本月第一天的日期时间
        const monthEnd = new Date(eDate.getFullYear(), eDate.getMonth() + 1, 0, 23, 59, 59); // 获取本月最后一天的日期时间（时间为23:59:59）
        this.listQuery.startTime = this.dateFormatter(monthStart)
        this.listQuery.endTime = this.dateFormatter(monthEnd)
      }else {
        this.listQuery.startTime = ''
        this.listQuery.endTime = ''
      }
    },
    getAllManufacture() {
      // 获取制造商列表
      dataAnalyseApi.getManufacturers().then(res => {
        this.manufacturerList = res.data
      })
    },
    getList() {
      dataAnalyseApi.getList(this.listQuery).then(res => {
        this.rows = res.data
        if (this.listQuery.percentDifference?.length > 0) {
          const decimal = parseFloat(this.listQuery.percentDifference.replace(/%/g, "")) / 100
          this.rows = this.rows.filter(item => {
            return Number(item.percentDifference) >= decimal
          })
        }
      })
    },
    handleQuery() {
      console.log(this.dateRange)
      console.log(this.listQuery.startTime)
      if (!this.listQuery?.startTime ?? 0) {
        ElMessage.warning('请选择日期')
        return
      }
      this.getList()
    },
    selectedProductions(data) {
      this.selectedProductionList = data
    },
    exportAnalyseData() {
      if (!this.selectedProductionList || this.selectedProductionList.length < 1) {
        this.$message.warning("请勾选数据")
        return
      }
      // dataAnalyseApi.exportAnalyseData()
      const headers = ['ODM 制造商', '标签申请数', '商品制造数', '生产差额', '差额百分比']
      const exportData = this.selectedProductionList.map(item =>
          [item.manufacturerName,item.labelNum,item.productNum,item.difference,item.percentDifference * 100 + '%'])
      const worksheet = XLSX.utils.aoa_to_sheet([headers, ...exportData])
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, '生产数据分析')
      XLSX.writeFile(workbook, '生产数据分析.xlsx')
    },
    dateFormatter(date) {
      date = (date ? new Date(date) : new Date)
      const formatter = 'YYYY-MM-DD HH:mm:ss'
      const Y = date.getFullYear() + '',
          M = date.getMonth() + 1,
          D = date.getDate(),
          H = date.getHours(),
          m = date.getMinutes(),
          s = date.getSeconds()
      return formatter.replace(/YYYY|yyyy/g, Y)
          .replace(/YY|yy/g, Y.substr(2, 2))
          .replace(/MM/g, (M < 10 ? '0' : '') + M)
          .replace(/DD/g, (D < 10 ? '0' : '') + D)
          .replace(/HH|hh/g, (H < 10 ? '0' : '') + H)
          .replace(/mm/g, (m < 10 ? '0' : '') + m)
          .replace(/ss/g, (s < 10 ? '0' : '') + s)
    },
  }
}
</script>

<style scoped>

</style>
